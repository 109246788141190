import React from 'react'
import { Button, Col, Form, Modal, Row, Collapse, Typography, TimePicker, Divider, Flex } from 'antd'
import { MinusSquareTwoTone, PlusSquareTwoTone } from '@ant-design/icons'

import { useStudiosFormWorkTimeModal } from './studios-form-work-time-modal.hook'
import './studios-form-work-time-modal.styles.less'
import { StudiosFormWorkTimeModalProps } from './studios-form-work-time-modal.types'
import { formatWeekdays, formatWeekend } from '../../../../format/text.format'
import { isDef } from '../../../../types/lang.types'

const { Panel } = Collapse

const format = 'HH:mm'

export const StudiosFormWorkTimeModal: React.FC<StudiosFormWorkTimeModalProps> = props => {
  const { values, offset, open, onOk, onCancel } = props

  const {
    form,
    activeKeys,
    weekdays,
    weekend,
    weekdaysTime,
    weekendTime,
    onChangeWeekdaysTimeFrom,
    onChangeWeekdaysTimeTo,
    onChangeWeekendTimeFrom,
    onChangeWeekendTimeTo,
    onCollapseChange,
    onCloseModal,
    onSubmitForm,
  } = useStudiosFormWorkTimeModal({ values, offset, open, onCancel, onOk })

  return (
    <Modal
      forceRender
      title="Настроить дни недели"
      open={open}
      onCancel={onCloseModal}
      cancelText="Отмена"
      okText="Сохранить"
      className="studios-form-work-time-modal"
      footer={null}
      width={365}
    >
      <Divider className="studios-form-work-time-modal__divider" />
      <Form form={form} name="studioWorkTime" onFinish={onSubmitForm}>
        <Row justify="start" gutter={6}>
          <Col flex="auto">
            <Typography.Text type="secondary">День недели</Typography.Text>
          </Col>
          <Col flex="74px">
            <Typography.Text type="secondary">Начало</Typography.Text>
          </Col>
          <Col flex="74px">
            <Typography.Text type="secondary">Конец</Typography.Text>
          </Col>
        </Row>
        <Collapse
          defaultActiveKey={['1']}
          ghost
          onChange={key => {
            if (Array.isArray(key)) {
              onCollapseChange(key)
            }
          }}
          activeKey={activeKeys}
        >
          <Panel
            header={
              <Row justify="start" gutter={6}>
                <Col flex="auto">
                  {activeKeys.includes('1') ? <MinusSquareTwoTone /> : <PlusSquareTwoTone />}
                  <Typography.Text className="studios-form-work-time-modal__days-categories">Будни</Typography.Text>
                </Col>
                <Col flex="74px" onClick={e => e.stopPropagation()}>
                  <Form.Item>
                    <TimePicker
                      format={format}
                      suffixIcon={null}
                      placeholder="чч:мм"
                      value={weekdaysTime.from}
                      onChange={time => {
                        onChangeWeekdaysTimeFrom(time)
                      }}
                      allowClear={false}
                    />
                  </Form.Item>
                </Col>
                <Col flex="74px" onClick={e => e.stopPropagation()}>
                  <Form.Item>
                    <TimePicker
                      format={format}
                      suffixIcon={null}
                      placeholder="чч:мм"
                      value={weekdaysTime.to}
                      onChange={time => {
                        onChangeWeekdaysTimeTo(time)
                      }}
                      allowClear={false}
                    />
                  </Form.Item>
                </Col>
              </Row>
            }
            key="1"
            showArrow={false}
            className="studios-form-work-time-modal__collapse-panel"
          >
            <Flex vertical gap="8px">
              {weekdays.map((day, index) => {
                return (
                  <Row justify="start" key={index} gutter={6}>
                    <Col flex="auto" className="studios-form-work-time-modal__day">
                      {formatWeekdays(day)}
                    </Col>
                    <Col flex="74px">
                      <Form.Item name={['weekdays', day, 'from']}>
                        <TimePicker
                          value={weekendTime.from}
                          format={format}
                          suffixIcon={null}
                          placeholder="чч:мм"
                          onChange={time => {
                            form.setFieldValue(['weekdays', day, 'from'], time)
                            form.validateFields([['weekdays', day, 'to']])
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col flex="74px">
                      <Form.Item
                        name={['weekdays', day, 'to']}
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              const timeFrom = form.getFieldValue(['weekdays', day, 'from'])
                              if (isDef(timeFrom) && isDef(value) && timeFrom.isAfter(value)) {
                                return Promise.reject('Недопустимое время окончания')
                              } else {
                                return Promise.resolve()
                              }
                            },
                          }),
                        ]}
                      >
                        <TimePicker
                          value={weekendTime.to}
                          format={format}
                          suffixIcon={null}
                          placeholder="чч:мм"
                          onChange={time => {
                            form.setFieldValue(['weekdays', day, 'to'], time)
                            form.validateFields([['weekdays', day, 'to']])
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )
              })}
            </Flex>
          </Panel>
          <Panel
            header={
              <Row justify="start" gutter={6}>
                <Col flex="auto">
                  {activeKeys.includes('2') ? <MinusSquareTwoTone /> : <PlusSquareTwoTone />}
                  <Typography.Text className="studios-form-work-time-modal__days-categories">Выходные</Typography.Text>
                </Col>
                <Col flex="74px" onClick={e => e.stopPropagation()}>
                  <Form.Item>
                    <TimePicker
                      format={format}
                      suffixIcon={null}
                      placeholder="чч:мм"
                      value={weekendTime.from}
                      onChange={time => {
                        onChangeWeekendTimeFrom(time)
                      }}
                      allowClear={false}
                    />
                  </Form.Item>
                </Col>
                <Col flex="74px" onClick={e => e.stopPropagation()}>
                  <Form.Item>
                    <TimePicker
                      format={format}
                      suffixIcon={null}
                      placeholder="чч:мм"
                      value={weekendTime.to}
                      onChange={time => {
                        onChangeWeekendTimeTo(time)
                      }}
                      allowClear={false}
                    />
                  </Form.Item>
                </Col>
              </Row>
            }
            key="2"
            showArrow={false}
            className="studios-form-work-time-modal__collapse-panel"
          >
            <Flex vertical gap="8px">
              {weekend.map((day, index) => {
                return (
                  <Row justify="start" key={index} gutter={6}>
                    <Col flex="auto" className="studios-form-work-time-modal__day">
                      {formatWeekend(day)}
                    </Col>
                    <Col flex="74px">
                      <Form.Item name={['weekend', day, 'from']}>
                        <TimePicker
                          value={weekendTime.from}
                          format={format}
                          suffixIcon={null}
                          placeholder="чч:мм"
                          onChange={time => {
                            form.setFieldValue(['weekend', day, 'from'], time)
                            form.validateFields([['weekend', day, 'to']])
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col flex="74px">
                      <Form.Item
                        name={['weekend', day, 'to']}
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              const timeFrom = form.getFieldValue(['weekend', day, 'from'])
                              if (isDef(timeFrom) && isDef(value) && timeFrom.isAfter(value)) {
                                return Promise.reject('Недопустимое время окончания')
                              } else {
                                return Promise.resolve()
                              }
                            },
                          }),
                        ]}
                      >
                        <TimePicker
                          value={weekendTime.to}
                          format={format}
                          suffixIcon={null}
                          placeholder="чч:мм"
                          onChange={time => {
                            form.setFieldValue(['weekend', day, 'to'], time)
                            form.validateFields([['weekend', day, 'to']])
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )
              })}
            </Flex>
          </Panel>
        </Collapse>
        <Flex className="studios-form-work-time-modal__buttons" justify="end" gap={16}>
          <Button onClick={onCloseModal}>Отменить</Button>
          <Button type="primary" htmlType="submit">
            Отправить
          </Button>
        </Flex>
      </Form>
    </Modal>
  )
}
