import * as React from 'react'
import { Button, Card, Flex, Tag, Typography } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, EyeOutlined, SyncOutlined } from '@ant-design/icons'

import { formatReceiptNumber, formatTransactionReceiptType } from '../../../../format/text.format'
import { ReceiptsListItemData } from './receipts-list-item.types'
import './receipts-list-item.styles.less'

export const ReceiptsListItem: React.FC<ReceiptsListItemData> = props => {
  const { number, link, type, status } = props

  const isErrorStatus = status === 'fail'
  const isWaitingStatus = status === 'wait' || status === 'new'
  const isErrorType = [
    'SUBSCRIPTION_REFUND',
    'FULL_PAYMENT_SERVICE_REFUND',
    'SERVICE_REFUND',
    'VISIT_REFUND_CORRECTION',
    'GOODS_REFUND',
  ].includes(type)

  const tagColor = isWaitingStatus ? 'processing' : isErrorStatus || isErrorType ? 'error' : 'success'
  const tagIcon = isWaitingStatus ? (
    <SyncOutlined />
  ) : isErrorStatus || isErrorType ? (
    <CloseCircleOutlined />
  ) : (
    <CheckCircleOutlined />
  )
  const tagText = isErrorStatus
    ? `"${formatTransactionReceiptType(type)}" не был отправлен`
    : isWaitingStatus
    ? `Ожидание отправки чека "${formatTransactionReceiptType(type)}"`
    : formatTransactionReceiptType(type)

  return (
    <Card className="receipts-list-item">
      <Flex align="center" justify="space-between" gap="large">
        <Flex vertical gap={4} align="baseline">
          <Tag icon={tagIcon} color={tagColor}>
            {tagText}
          </Tag>
          <Typography.Text strong className="receipts-list-item__number">
            {formatReceiptNumber(number, isWaitingStatus)}
          </Typography.Text>
        </Flex>

        {link && (
          <a href={link} target="_blank" rel="noreferrer">
            <Button icon={<EyeOutlined />} />
          </a>
        )}
      </Flex>
    </Card>
  )
}
