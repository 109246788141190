import React from 'react'
import { Form, Button, TabsProps, Flex, Tabs } from 'antd'

import { useStudiosCreateForm } from './studios-form-hook'
import { genStudioInitialValues } from './studios-form-utils'
import { StudiosFormComponentProps } from './studios-form-types'
import { StudiosFormGeneralInfo } from './studios-form-general-info/studios-form-general-info.component'
import { StudiosFormRooms } from './studios-form-rooms/studios-form-rooms.component'
import './studios-form.styles.less'
import { StudiosFormTills } from './studios-form-tills/studios-form-tills.component'

export const StudiosFormComponent = ({
  isLoading,
  form,
  franchisesOptions,
  directionsOptions,
  colorsOptions,
  isEdit = false,
  onFinishHandler,
  onSaveStudioRoomHandler,
  onRemoveStudioRoomHandler,
  customFields,
  handleCustomFieldsChange,
}: StudiosFormComponentProps) => {
  const { currentTab, onNextTab, onChangeCurrentTab } = useStudiosCreateForm(isEdit, form)

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Общая информация',
      children: (
        <StudiosFormGeneralInfo
          onChangeCustomFields={handleCustomFieldsChange}
          customFields={customFields ? customFields : []}
          form={form}
          isEdit={isEdit}
          franchisesOptions={franchisesOptions}
        />
      ),
    },
    {
      key: '2',
      label: 'Пространства',
      children: (
        <StudiosFormRooms
          form={form}
          isEdit={isEdit}
          directionsOptions={directionsOptions}
          colorsOptions={colorsOptions}
          onRemoveRoomHandler={onRemoveStudioRoomHandler}
          onSaveRoomHandler={onSaveStudioRoomHandler}
        />
      ),
    },
    {
      key: '3',
      label: 'Дополнительные настройки',
      children: <StudiosFormTills form={form} />,
    },
  ]

  return (
    <Form
      layout="vertical"
      autoComplete="off"
      name="studioCreate"
      form={form}
      onFinish={onFinishHandler}
      initialValues={genStudioInitialValues()}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      disabled={isLoading}
    >
      <Flex vertical gap="large" style={{ maxWidth: '1128px' }}>
        <Tabs
          size="large"
          defaultActiveKey="1"
          activeKey={currentTab}
          onChange={onChangeCurrentTab}
          type="card"
          items={items}
        />
        {currentTab === '3' ? (
          <Form.Item style={{ alignSelf: 'flex-end' }}>
            <Button htmlType="submit" type="primary" size="large" style={{ width: '180px' }}>
              Сохранить
            </Button>
          </Form.Item>
        ) : (
          <Button
            type="primary"
            size="large"
            htmlType="button"
            onClick={onNextTab}
            style={{ width: '180px', alignSelf: 'flex-end' }}
          >
            Далее
          </Button>
        )}
      </Flex>
    </Form>
  )
}
