export enum AdvanceSettlementReceiptStatus {
  INITIAL = 'INITIAL',
  NOT_REQUIRED = 'NOT_REQUIRED',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  UNKNOWN = 'UNKNOWN',
}

export enum ReceiptType {
  SUBSCRIPTION_SELL = 'SUBSCRIPTION_SELL',
  SUBSCRIPTION_VISIT = 'SUBSCRIPTION_VISIT',
  SUBSCRIPTION_REFUND = 'SUBSCRIPTION_REFUND',
  SUBSCRIPTION_REFUND_COMMISSION = 'SUBSCRIPTION_REFUND_COMMISSION',
  SERVICE_REFUND_COMMISSION = 'SERVICE_REFUND_COMMISSION',
  AGENT_COMMISSION = 'AGENT_COMMISSION',
  FULL_PAYMENT_SERVICE_SELL = 'FULL_PAYMENT_SERVICE_SELL',
  FULL_PAYMENT_SERVICE_REFUND = 'FULL_PAYMENT_SERVICE_REFUND',
  SERVICE_SELL = 'SERVICE_SELL',
  SERVICE_REFUND = 'SERVICE_REFUND',
  GOODS_SELL = 'GOODS_SELL',
  GOODS_REFUND = 'GOODS_REFUND',
  VISIT_REFUND_CORRECTION = 'VISIT_REFUND_CORRECTION',
}
