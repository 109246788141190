import { Form, FormInstance, UploadFile } from 'antd'
import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AddressSuggestions } from 'react-dadata'

import { WorkTime } from '../../../../api/types/api.types'
import { formatOffsetToString } from '../../../../format/date.format'
import { isDef, isDefAndNotEmpty, Nullable } from '../../../../types/lang.types'
import { genDays, isContainArrayDaysWithSameTime } from '../../../../utils/days.utils'
import { StudiosFormTypes, StudiosRoomFormTypes } from '../studios-form-types'
import {
  genStudiosFormTimeToValidationRules,
  hasStudiosFormInvalidWorkTime,
  updateStudiosTimeWithOffset,
} from './studios-form-general-info.utils'

interface Props {
  form: FormInstance<StudiosFormTypes>
  isEdit?: boolean
}

export function useStudiosFormGeneralInfo(props: Props) {
  const { form, isEdit } = props

  const [photos, setPhotos] = useState<UploadFile[]>([])
  const [country, setCountry] = useState()

  const [isTagVisible, setIsTagVisible] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [timeDisabled, setTimeDisabled] = useState(false)

  const timeToValidationRules = useMemo(genStudiosFormTimeToValidationRules, [])

  const formPhotos = Form.useWatch('photos', form)
  const unifiedTime = Form.useWatch('unifiedTime', form)
  const workTime = Form.useWatch('workTime', form)
  const offset = Form.useWatch('offset', form)
  const onlineBusiness = Form.useWatch('onlineBusiness', form)
  const hideStudio = Form.useWatch('hideStudio', form)

  const addressRef = useRef<AddressSuggestions>(null)
  const countryRef = useRef<AddressSuggestions>(null)

  const days = useMemo(genDays, [])

  const toggleOnlineBusiness = () => {
    form.setFieldValue('onlineBusiness', !onlineBusiness)
  }

  const toggleHideStudio = () => {
    form.setFieldValue('hideStudio', !hideStudio)
  }

  const updateRoomsTime = (
    prevStudioWorkTime: Nullable<WorkTime>,
    weekdaysArray: any,
    unifiedTime: { start: string; end: string },
    isUnifiedTime: boolean
  ) => {
    const currentRooms = form.getFieldValue('rooms')

    const updatedRooms = currentRooms.map((room: StudiosRoomFormTypes) => {
      const isWorkTimeEmpty = !room.workTime || Object.keys(room.workTime).length === 0
      if (isWorkTimeEmpty || JSON.stringify(room.workTime) === JSON.stringify(prevStudioWorkTime)) {
        return {
          ...room,
          workTime: weekdaysArray,
          ...(isUnifiedTime && { unifiedTime }),
        }
      }
      return room
    })
    form.setFieldValue('rooms', updatedRooms)
  }

  const updateWorkTime = useCallback(
    (workTime: Nullable<WorkTime>, offset: string): Nullable<WorkTime> => {
      if (!workTime || Object.keys(workTime).length === 0) return null

      const weekdaysArray: any = {}

      days.forEach(weekday => {
        const timeRange = workTime[weekday]
        if (!timeRange) return

        weekdaysArray[weekday] = {
          from: updateStudiosTimeWithOffset(timeRange.from, offset),
          to: updateStudiosTimeWithOffset(timeRange.to, offset),
        }
      })
      return weekdaysArray
    },
    [days]
  )

  const updateFormValues = useCallback(
    (newOffset: string) => {
      const formValues = form.getFieldsValue()

      const updatedWorkTime = updateWorkTime(formValues.workTime, newOffset)
      const updatedRooms = Array.isArray(formValues.rooms)
        ? formValues.rooms.map(room => ({
            ...room,
            workTime: updateWorkTime(room.workTime, newOffset),
          }))
        : null

      form.setFieldsValue({
        workTime: updatedWorkTime,
        ...(updatedRooms && { rooms: updatedRooms }),
      })
    },
    [form, updateWorkTime]
  )

  const onChangeTimeHandler = (value: { start: string; end: string }): void => {
    const prevStudioWorkTime = form.getFieldValue('workTime')
    const offset = form.getFieldValue('offset')
    form.setFieldValue('unifiedTime', value)
    const weekdaysArray: any = {}

    days.forEach(weekday => {
      weekdaysArray[weekday] = {
        from: dayjs(value.start, 'HH:mm').utcOffset(offset, true).format('HH:mmZ'),
        to: dayjs(value.end, 'HH:mm').utcOffset(offset, true).format('HH:mmZ'),
      }
    })

    if (!hasStudiosFormInvalidWorkTime(weekdaysArray)) {
      form.setFieldValue('workTime', weekdaysArray)
      !isEdit && updateRoomsTime(prevStudioWorkTime, weekdaysArray, value, true)
    }
  }

  const onChangePhotos = (photos: UploadFile[]) => {
    form.setFieldValue('photos', photos)
    setPhotos(photos)
  }

  const onOpenModal = () => {
    setIsModalOpen(true)
  }

  const onModalCancel = () => {
    setIsModalOpen(false)
  }

  const onModalConfirm = (values: Nullable<WorkTime>, unifiedTime: boolean) => {
    if (isDef(values)) {
      const prevStudioWorkTime = form.getFieldValue('workTime')
      form.setFieldValue('workTime', values)
      setTimeDisabled(!unifiedTime)

      let newUnifiedTime = form.getFieldValue('unifiedTime')

      if (unifiedTime) {
        newUnifiedTime = {
          start: dayjs(values.MONDAY.from, 'HH:mm').format('HH:mm'),
          end: dayjs(values.MONDAY.to, 'HH:mm').format('HH:mm'),
        }
        form.setFieldValue('unifiedTime', newUnifiedTime)
      }

      !isEdit && updateRoomsTime(prevStudioWorkTime, values, newUnifiedTime, unifiedTime)
      form.setFields([{ name: 'unifiedTime', errors: [] }])
    }
    onModalCancel()
  }

  useEffect(() => {
    if (!isDefAndNotEmpty(photos)) {
      setPhotos(form.getFieldValue('photos'))
    }
  }, [photos, form, formPhotos])

  useEffect(() => {
    const workTime = form.getFieldValue('workTime')
    if (workTime && Object.keys(workTime).length !== 0) {
      setTimeDisabled(!isContainArrayDaysWithSameTime(Object.values(workTime)))
    }
  }, [form, workTime])

  useEffect(() => {
    if (!form || !isDef(offset)) return

    const newOffsetString = formatOffsetToString(offset)
    updateFormValues(newOffsetString)
  }, [days, form, offset, updateFormValues])

  return {
    countryRef,
    addressRef,
    timeToValidationRules,
    photos,
    unifiedTime,
    country,
    timeDisabled,
    isModalOpen,
    isTagVisible,
    toggleOnlineBusiness,
    toggleHideStudio,
    setIsTagVisible,
    setCountry,
    onChangeTimeHandler,
    onChangePhotos,
    onOpenModal,
    onModalCancel,
    onModalConfirm,
  }
}
