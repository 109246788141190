import React, { useEffect, useMemo, useState } from 'react'
import { Form, FormInstance, FormListFieldData, RadioChangeEvent, Row, Typography, UploadFile } from 'antd'
import dayjs from 'dayjs'

import { isDef, isDefAndNotEmpty, Nullable } from '../../../../../types/lang.types'
import { StudiosFormTypes } from '../../studios-form-types'
import { onRemoveRooms } from './studios-form-room.utils'
import {
  genStudiosFormTimeToValidationRules,
  hasStudiosFormInvalidWorkTime,
} from '../../studios-form-general-info/studios-form-general-info.utils'
import { genDays, isContainArrayDaysWithSameTime } from '../../../../../utils/days.utils'
import { WorkTime } from '../../../../../api/types/api.types'
import { useCustomFieldsSettings } from '../../../../custom-fields-settings/custom-fields-settings.hook'
import { StudioRoomScheme } from './studios-form-room.types'
import { getStrEnumValues } from '../../../../../utils/enum.utils'

interface Props {
  form: FormInstance<StudiosFormTypes>
  field: FormListFieldData
  fields: FormListFieldData[]
  index: number
  activeKeys: string[]
  onCollapseChange: (key: string[]) => void
  onRemoveRoomHandler?: (key: number) => void
  onSaveRoomHandler?: (key: number) => void
  remove: (index: number | number[]) => void
}

export function useStudiosFormRoom(props: Props) {
  const { form, field, fields, index, activeKeys } = props
  const { onCollapseChange, onRemoveRoomHandler, onSaveRoomHandler, remove } = props

  const [photos, setPhotos] = useState<UploadFile[]>([])

  const [scheme, setScheme] = useState(StudioRoomScheme.SINGLE)
  const [tags, setTags] = useState<StudioRoomScheme[]>(getStrEnumValues(StudioRoomScheme))
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [timeDisabled, setTimeDisabled] = useState(false)

  const formPhotos = Form.useWatch([field.name, 'photos'], form)
  const unifiedTime = Form.useWatch(['rooms', field.name, 'unifiedTime'], form)
  const workTime = Form.useWatch(['rooms', field.name, 'workTime'], form)

  const hasRoomName = form.getFieldValue('rooms')[index] && form.getFieldValue('rooms')[index]?.name

  const panelHeader = (
    <React.Fragment>
      <Row onClick={event => event.stopPropagation()}>
        <Typography.Title level={activeKeys.includes(index.toString()) ? 4 : 5} className="studios-form__title">
          {hasRoomName
            ? form.getFieldValue('rooms')[index].name
            : activeKeys.includes(index.toString())
            ? 'Добавить пространство'
            : 'Новое пространство'}
        </Typography.Title>
      </Row>
    </React.Fragment>
  )

  const days = useMemo(genDays, [])

  const customFields = form.getFieldValue(['rooms', field.name, 'customFields'])

  const { handleInputChange } = useCustomFieldsSettings({
    customFields: customFields || null,
  })

  const toggleScheme = (e: RadioChangeEvent) => {
    if (e.target?.value) {
      setScheme(e.target.value)
    }
  }

  const handleCloseTag = (tag: StudioRoomScheme) => {
    setTags(prevTags => prevTags.filter(revTag => revTag !== tag))
  }

  const onChangePhotos = (photos: UploadFile[]) => {
    form.setFieldValue([field.name, 'photos'], photos)
    setPhotos(photos)
  }

  const onChangeTimeHandler = (value: { start: string; end: string }): void => {
    const offset = form.getFieldValue('offset')
    const values = form.getFieldsValue()
    const weekdaysArray: any = {}

    days.forEach(weekday => {
      weekdaysArray[weekday] = {
        from: dayjs(value.start, 'HH:mm').utcOffset(offset, true).format('HH:mmZ'),
        to: dayjs(value.end, 'HH:mm').utcOffset(offset, true).format('HH:mmZ'),
      }
    })

    if (!hasStudiosFormInvalidWorkTime(weekdaysArray)) {
      form.setFieldsValue({
        ...values,
        rooms: values.rooms.map((room, index) => {
          return index === field.name ? { ...room, workTime: weekdaysArray } : room
        }),
      })
    }
  }

  const timeToValidationRules = useMemo(genStudiosFormTimeToValidationRules, [])

  const onEditHandler = React.useCallback(() => {
    onCollapseChange([])
    setTimeout(() => onCollapseChange([String(index)]), 100)
  }, [index, onCollapseChange])

  const onRemoveHandler = React.useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      onRemoveRooms(
        e,
        isDef(onRemoveRoomHandler)
          ? () => {
              onRemoveRoomHandler(index)
              remove(index)
            }
          : () => {
              if (fields.length === 2) {
                onCollapseChange(['0'])
              }
              remove(index)
            }
      )
    },
    [fields.length, index, onCollapseChange, onRemoveRoomHandler, remove]
  )

  const onCancelHandler = React.useCallback(() => {
    const filteredKeys = activeKeys.filter(key => key !== `${index}`)
    onCollapseChange(filteredKeys)
  }, [activeKeys, index, onCollapseChange])

  const onOpenModal = () => {
    setIsModalOpen(true)
  }

  const onModalCancel = () => {
    setIsModalOpen(false)
  }

  const onModalConfirm = (values: Nullable<WorkTime>, unifiedTime: boolean) => {
    if (isDef(values)) {
      form.setFieldValue(['rooms', field.name, 'workTime'], values)
      setTimeDisabled(!unifiedTime)
      if (unifiedTime) {
        form.setFieldValue(['rooms', field.name, 'unifiedTime'], {
          start: dayjs(values.MONDAY.from, 'HH:mm').format('HH:mm'),
          end: dayjs(values.MONDAY.to, 'HH:mm').format('HH:mm'),
        })
      }
      form.setFields([{ name: ['rooms', field.name, 'unifiedTime'], errors: [] }])
    }
    onModalCancel()
  }

  const onSaveHandler = React.useCallback(() => {
    form
      .validateFields([
        ['rooms', index, 'name'],
        ['rooms', index, 'totalCapacity'],
        ['rooms', index, 'color'],
        ['rooms', index, 'unifiedTime'],
      ])
      .then(() => {
        if (isDef(onSaveRoomHandler)) {
          onSaveRoomHandler(index)
        }
        const filteredKeys = activeKeys.filter(key => key !== `${index}`)
        onCollapseChange(filteredKeys)
      })
      .catch(errors => {})
  }, [activeKeys, form, index, onCollapseChange, onSaveRoomHandler])

  useEffect(() => {
    const photosValue = form.getFieldValue(['rooms', field.name, 'photos'])
    if (!isDefAndNotEmpty(photos) && isDefAndNotEmpty(photosValue)) {
      setPhotos(photosValue)
    }
  }, [photos, form, field.name, formPhotos])

  useEffect(() => {
    const workTime = form.getFieldValue(['rooms', field.name, 'workTime'])
    if (workTime && Object.keys(workTime).length !== 0) {
      setTimeDisabled(!isContainArrayDaysWithSameTime(Object.values(workTime)))
    }
  }, [field.name, form, workTime])

  return {
    timeToValidationRules,
    panelHeader,
    photos,
    unifiedTime,
    timeDisabled,
    isModalOpen,
    scheme,
    customFields,
    tags,
    handleCloseTag,
    toggleScheme,
    handleInputChange,
    onOpenModal,
    onModalConfirm,
    onModalCancel,
    onChangeTimeHandler,
    onChangePhotos,
    onEditHandler,
    onRemoveHandler,
    onCancelHandler,
    onSaveHandler,
  }
}
