import { PlusOutlined } from '@ant-design/icons'
import { Button, Collapse, Flex, Form, Typography } from 'antd'

import { CustomCard } from '../../../custom-card/custom-card.component'
import { StudiosFormRoom } from './studios-form-room/studios-form-room.component'
import { useStudiosFormRooms } from './studios-form-rooms.hook'
import { StudiosFormRoomsProps } from './studios-form-rooms.types'

export const StudiosFormRooms: React.FC<StudiosFormRoomsProps> = props => {
  const { isEdit, form, ...rest } = props

  const { activeKeys, onCollapseChange, setRoomWorkTime } = useStudiosFormRooms({ form, isEdit })

  return (
    <CustomCard>
      <Flex gap="large" vertical>
        <Typography.Title level={4} className={'studios-form__title'}>
          Пространства
        </Typography.Title>
        <Form.List name="rooms">
          {(fields, { add, remove, move }) => {
            return (
              <>
                <Collapse
                  onChange={key => {
                    if (Array.isArray(key)) {
                      onCollapseChange(key)
                    }
                  }}
                  activeKey={activeKeys}
                  ghost
                  bordered={false}
                >
                  {fields.map((field, i) => {
                    return (
                      <StudiosFormRoom
                        {...rest}
                        form={form}
                        isEdit={isEdit}
                        fields={fields}
                        field={field}
                        index={i}
                        key={`${i}`}
                        activeKeys={activeKeys}
                        onCollapseChange={onCollapseChange}
                        remove={remove}
                      />
                    )
                  })}
                </Collapse>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  style={{ alignSelf: 'flex-start' }}
                  onClick={() => {
                    onCollapseChange([])

                    if (!isEdit) {
                      onCollapseChange(['0'])
                      add(0)
                      move(fields.length, 0)
                    } else {
                      onCollapseChange([fields.length.toString()])
                      add(0)
                    }
                    setRoomWorkTime(isEdit ? fields.length.toString() : '0')
                  }}
                >
                  Добавить пространство
                </Button>
              </>
            )
          }}
        </Form.List>
      </Flex>
    </CustomCard>
  )
}
