import { Rule } from 'antd/lib/form'
import dayjs from 'dayjs'

import { WorkTime } from '../../../../api/types/api.types'

export function genStudiosFormTimeToValidationRules(): Rule[] {
  return [
    { required: true, message: 'Укажите время' },
    ({ getFieldValue }) => ({
      validator(rule, value: { start: string; end: string }) {
        if (!value || !value.start || !value.end) {
          return Promise.reject('Заполните время начала и окончания')
        }

        const startTime = dayjs(value.start, 'HH:mm')
        const endTime = dayjs(value.end, 'HH:mm')

        if (!startTime.isValid()) {
          return Promise.reject('Заполните время начала')
        } else if (!endTime.isValid()) {
          return Promise.reject('Заполните время окончания')
        } else if (startTime.isAfter(endTime)) {
          return Promise.reject('Недопустимое время окончания')
        } else if (startTime.isSame(endTime)) {
          return Promise.reject('Время начала не может быть равно времени окончания')
        } else {
          return Promise.resolve()
        }
      },
    }),
  ]
}

export function hasStudiosFormInvalidWorkTime(workTime: WorkTime): boolean {
  return Object.values(workTime).some(
    ({ from, to }) => !from || from === 'Invalid Date' || !to || to === 'Invalid Date'
  )
}

export function updateStudiosTimeWithOffset(time: string, newOffset: string): string {
  if (!time) return ''
  const [timePart] = time.split(/[+-]/)
  return timePart ? `${timePart}${newOffset}` : ''
}
