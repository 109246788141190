import { FormInstance } from 'antd'
import { useCallback, useEffect, useState } from 'react'

import { StudiosFormTypes } from '../studios-form-types'

interface Props {
  form: FormInstance<StudiosFormTypes>
  isEdit?: boolean
}

export function useStudiosFormRooms(props: Props) {
  const { form, isEdit } = props

  const [activeKeys, setActiveKeys] = useState<string[]>([])

  const onCollapseChange = (key: string[]) => {
    setActiveKeys(key)
  }

  const setRoomWorkTime = useCallback(
    (key: string) => {
      const unifiedTimeValues = form.getFieldValue('unifiedTime')
      const workTimeValues = form.getFieldValue('workTime')

      form.setFieldValue(['rooms', key, 'unifiedTime'], unifiedTimeValues)
      form.setFieldValue(['rooms', key, 'workTime'], workTimeValues)
    },
    [form]
  )

  useEffect(() => {
    if (!isEdit) {
      setRoomWorkTime('0')
      setActiveKeys(['0'])
    }
  }, [isEdit, setRoomWorkTime])

  return {
    activeKeys,
    onCollapseChange,
    setRoomWorkTime,
  }
}
